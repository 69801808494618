import React from 'react';
import { useTranslation } from 'react-i18next';

const Amenity = ({ id, value }) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4">
      <img
        src={`../../../img/amenity-icons/${id}.svg`}
        alt={value}
        className="tw-h-8"
      />
      <span className="tw-text-xs tw-font-bold tw-text-center tw-text-gray-700 tw-uppercase">
        {value}
      </span>
    </div>
  );
};

const LocationAmenityList = ({ location }) => {
  const { t } = useTranslation();
  return (
    <div>
      {!!location?.amenities?.length && (
        <div>
          <h2 className="tw-text-lg tw-text-blue tw-uppercase">
            {t('Amenities')}
          </h2>
          <div className="tw-gap-8 tw-flex tw-mt-4">
            {location.amenities.map((amenity) => (
              <Amenity
                key={amenity.id}
                id={amenity.id}
                value={t(amenity.value)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationAmenityList;
